import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStoreService } from '../services/stores/token-store.service';

/**
 * HTTP authorization interceptor
 *
 * Add authorization headers to outgoing API requests
 */

/**
 * Add authorization headers to given request.
 *
 * @param request Request
 *
 * @return request clone with headers added
 */
const addAuthorizationHeaders = (request: HttpRequest<unknown>) => {
  return request.clone({ setHeaders: getAuthorizationHeaders() });
};

/**
 * Get authorization headers with current authentication token
 *
 * @return HTTP headers
 */
const getAuthorizationHeaders = () => {
  return {
    Authorization: `Bearer ${inject(TokenStoreService).$accessToken()}`,
  };
};

export const authorizationInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  if (!request.withCredentials) {
    // Only protect API calls
    return next(request);
  }

  const token = inject(TokenStoreService).$accessToken();
  if (!token) {
    // Unauthenticated
    return next(request);
  }

  return next(addAuthorizationHeaders(request));
};
