import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthRequest } from '../../models/api/authRequest';
import { Token } from '../../models/api/token';
import { Logger } from '../logger.service';
import { TokenIntrospection } from '../../models/api/TokenIntrospection';

const log = new Logger('AuthenticationApiService');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  private baseUrl = `${environment.apiUrl}/auth`;
  httpClient = inject(HttpClient);

  login(payload: AuthRequest) {
    return this.httpClient.post<Token>(`${this.baseUrl}/login`, payload).pipe(
      tap((data) => {
        log.debug(`Successfully loggin with token => `, data);
      })
    );
  }

  logout() {
    return this.httpClient
      .post<Token>(
        `${this.baseUrl}/logout`,
        {},
        {
          withCredentials: true,
        }
      )
      .pipe(tap(() => log.debug(`Successfully loggout`)));
  }

  introspection(token: string) {
    return this.httpClient
      .get<TokenIntrospection>(`${this.baseUrl}/introspection?token=${token}`)
      .pipe(
        tap((data) => {
          log.debug(`Successfully introspected token => `, data);
        })
      );
  }
}
