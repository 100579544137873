import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { fr_FR, provideNzI18n } from 'ng-zorro-antd/i18n';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { routes } from './app.routes';
import { authorizationInterceptor } from './core/interceptors/authorization.interceptor';
import { errorInterceptor } from './core/interceptors/error.interceptor';

registerLocaleData(localeFr, 'fr');

const custom_fr_FR = {
  ...fr_FR,
  DatePicker: {
    ...fr_FR.DatePicker,
    lang: {
      ...fr_FR.DatePicker.lang,
      rangeQuarterPlaceholder: ['Trimestre de début', 'Trimestre de fin'],
    },
  },
  QRCode: {
    ...fr_FR.QRCode,
    scanned: 'Scanné',
  },
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      })
    ),
    provideHttpClient(
      withInterceptors([authorizationInterceptor, errorInterceptor])
    ),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNzI18n(custom_fr_FR),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'medium' } },
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      })
    ),
  ],
};
