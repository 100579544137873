{
  "name": "oremus-backoffice",
  "version": "2.2.7",
  "scripts": {
    "prepare": "husky install",
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "deploy-dev": "rm -rf dist && ng build --configuration development && cd dist && mv oremus-backoffice back-office && cd .. && tar cvf ./back-office.tar dist && scp back-office.tar oremus-dev:oremus-infra/backoffice",
    "deploy-prod": "rm -rf dist && ng build --configuration production && cd dist && mv oremus-backoffice back-office && cd .. && tar cvf ./back-office.tar dist && scp back-office.tar oremus-prod:oremus-infra/backoffice",
    "test": "ng test",
    "test:ci": "ng test -c=ci",
    "lint": "ng lint",
    "lint-staged": "lint-staged",
    "init": "npm ci && npm run gen-api",
    "gen-api": "rm -rf generated-sources && openapi-generator-cli generate -c openapitools.json --generator-key v1.0"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.3",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/forms": "^19.1.3",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@ant-design/icons-angular": "^19.0.0",
    "@fortawesome/fontawesome-free": "^6.7.2",
    "@messageformat/core": "^3.4.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@popperjs/core": "^2.11.8",
    "date-fns": "^4.1.0",
    "jwt-decode": "^4.0.0",
    "ng-zorro-antd": "^19.0.2",
    "ngx-translate-messageformat-compiler": "^7.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.4",
    "@angular-eslint/builder": "^19.0.2",
    "@angular/cli": "^19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@openapitools/openapi-generator-cli": "^2.15.3",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^12.11.1",
    "angular-eslint": "19.0.2",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.15.0",
    "husky": "^9.1.7",
    "jasmine-core": "~4.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "lint-staged": "^15.3.0",
    "postcss": "^8.4.49",
    "prettier": "^3.4.2",
    "typescript": "~5.5.4",
    "typescript-eslint": "8.15.0"
  }
}
