import { inject, Injectable } from '@angular/core';
import { UserPermission } from '../constants/user-permission.enum';
import { TokenStoreService } from './stores/token-store.service';

@Injectable()
export class PermissionManagerService {
  private readonly $_userPermissionList =
    inject(TokenStoreService).$userPermissions;

  isGranted(permission: UserPermission) {
    return this.$_userPermissionList().indexOf(permission) !== -1;
  }

  haveSomePermission(permissionList: UserPermission[]) {
    return permissionList.some((permission) =>
      this.$_userPermissionList().includes(permission)
    );
  }

  haveAllPermission(permissionList: UserPermission[]) {
    return permissionList.every((permission) =>
      this.$_userPermissionList().includes(permission)
    );
  }
}
