import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

/**
 * HTTP errorInterceptor interceptor
 *
 * Add errorInterceptor headers to outgoing API requests
 */
export const errorInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authenticationService = inject(AuthenticationService);
  const nzNotificationService = inject(NzNotificationService);
  const translateService = inject(TranslateService);

  return next(request).pipe(
    catchError((err) => {
      switch (err.status) {
        case 401:
          authenticationService.clearSession();
          break;
        case 403:
          nzNotificationService.warning(
            translateService.instant('NOTIFICATION.ERROR'),
            translateService.instant('NOTIFICATION.INTERNAL_ERROR')
          );
          break;
        case 408:
          nzNotificationService.warning(
            translateService.instant('NOTIFICATION.ERROR'),
            translateService.instant('NOTIFICATION.INTERNAL_ERROR')
          );
          break;
        case 409:
          nzNotificationService.warning(
            translateService.instant('NOTIFICATION.WARNING'),
            translateService.instant('NOTIFICATION.CONFLICT')
          );
          break;
        case 415:
          nzNotificationService.warning(
            translateService.instant('NOTIFICATION.ERROR'),
            translateService.instant('NOTIFICATION.INTERNAL_ERROR')
          );
          break;
        case 500:
          nzNotificationService.error(
            translateService.instant('NOTIFICATION.ERROR'),
            translateService.instant('NOTIFICATION.INTERNAL_ERROR')
          );
          break;
      }

      return throwError(() => err);
    })
  );
};
