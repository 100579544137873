import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'orm-root',
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  title = 'oremus';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('fr');
  }
}
