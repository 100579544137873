/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStoreService } from '../services/stores/token-store.service';

export const authorizationGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const tokenStoreService = inject(TokenStoreService);

  if (tokenStoreService.$isAuthenticated()) {
    return true;
  }

  tokenStoreService.clear();
  inject(Router).navigate(['/login']);
  return false;
};

export const canMatchGuard: CanMatchFn = (
  _route: Route,
  _segments: UrlSegment[]
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> => {
  const tokenStoreService = inject(TokenStoreService);

  if (tokenStoreService.$isAuthenticated()) {
    return true;
  }

  tokenStoreService.clear();
  inject(Router).navigate(['/login']);
  return false;
};
