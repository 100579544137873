import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthRequest } from '../models/api/authRequest';
import { UserBoResetPasswordForm } from '../models/api/userBoResetPasswordForm';
import { AuthenticationApiService } from './api/authentication.api.service';
import { Logger } from './logger.service';
import { TokenStoreService } from './stores/token-store.service';

const log = new Logger('AuthenticationService');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly router = inject(Router);
  private readonly authenticationApiService = inject(AuthenticationApiService);
  private readonly tokenStoreService = inject(TokenStoreService);

  clearSession() {
    log.debug('clear session');
    this.tokenStoreService.clear();
    this.router.navigate(['/']);
  }

  login(payload: AuthRequest) {
    log.debug('login');
    return this.authenticationApiService.login(payload).pipe(
      tap((token) => {
        if (!token.isTmpPassword) {
          this.tokenStoreService.setToken(token);
        }
      })
    );
  }

  resetPassword(payload: UserBoResetPasswordForm) {
    log.debug('reset password');
    return this.authenticationApiService.login(payload).pipe(
      tap((token) => {
        if (!token.isTmpPassword) {
          this.tokenStoreService.setToken(token);
        }
      })
    );
  }

  logout() {
    log.debug('logout');
    return this.authenticationApiService.logout().pipe(
      tap(() => {
        this.clearSession();
      })
    );
  }

  introspectToken(token: string) {
    log.debug('introspect token');
    return this.authenticationApiService.introspection(token);
  }
}
